body {
	font-family: 'Lato', sans-serif;
	margin: 0 auto;
	overflow-x: hidden;
	background-color: #fafafa;
}

.container {
	max-width: 1290px;
	width: 100%;
	margin: 0 auto;

}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-vertical-center {
	display: flex;
	align-items: center;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.app-video-1 {
	width: 200px;
	transform: rotate(5deg);
}

.section-title {
	font-size: 2rem;
	font-weight: bold;
}

.section-title-2 {
	font-size: 1.5rem;
	font-weight: bold;
}

.button {
	display: inline-block;
	border: 1px solid black;
	text-decoration: none;
	padding: 15px 30px;
	border-radius: 5px;
	color: black;
	background-color: white;
	transition: background-color 0.2s linear;
}

.button:hover {
	color: #ffffff !important;
	background-color: rgba(0,0,0,0.8);
}

.box {
	margin: 1rem;
	height: 200px;
	padding: 10px;
	border-radius: 10px;
}

.box.box-shadow {
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
}

.box-title {
	font-size: 3rem;
}

.box-title-2 {
	font-size: 2rem;
}

.box-subtitle {
	font-size: 1rem;
}

.box-subtitle-2 {
	font-size: 1.5rem;
}

.chip-list {
	display: flex;
	flex-wrap: wrap;
}

.chip-list .chip:not(:last-child) {
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.chip-list .chip {
	background-color: #cccccc;
	padding: 1rem 2rem;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
	border-radius: 2rem;
	height: 19px;
}

.floating-img {
	width: 300px;
	position: fixed;
	bottom: 0;
	right: 50px;
}

.floating-img img {
	display: block;
}

/* Utilities */
.text-center {
	text-align: center;
}

.text-bold {
	font-weight: bold;
}

.text-thin {
	font-weight: 100;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-white {
	color: white;
}

.text-black {
	white: black;
}

.bg-white {
	background-color: white;
}

.bg-light-gray {
	background-color: #f5f5f5;
}

.h-100 {
	height: 100%;
}

.w-100 {
	width: 100%;
}

.p-1 {
	padding: 1rem;
}

.px-1 {
	padding-right: 5rem;
	padding-left: 5rem;
}

.py-3 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.py-5 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.pb-3 {
	padding-bottom: 3rem;
}

.m-1 {
	margin: 1rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mt-5 {
	margin-top: 5rem;
}

/* Media */
@media screen and (max-width: 960px)  {
	.flex-center, .flex-vertical-center, .flex-space-between {
		flex-direction: column;
		justify-content: center;
	}

	.order-1 {
		order: 1
	}

	.order-2 {
		order: 2
	}

	.app-video-1 {
		width: 100%;
		transform: rotate(0deg);
		text-align: center;
	}

	.app-video-1 .video-container {
		margin: 1rem;
	}

	.box-title {
		font-size: 2rem;
	}
}
