.navbar {
	width: 100%;
	height: 100px;
	color: white;
	top: 0;
	z-index: 999;
	background: rgba(0,0,0,0.9);
}

.navbar-offset {
	padding-top: 100px;
	height: calc(100% - 100px);
}

.navbar-logo {
	font-size: 2rem;
	font-weight: 600;
}

.navbar-menu {
	display: flex;
}

.navbar-menu-item {
	color: white;
	text-decoration: none;
}


.navbar-menu-item:not(:last-child) {
	margin-right: 1rem;
}

@media screen and (max-width: 960px)  {
	.navbar {
		height: 64px;
	}

	.navbar-menu {
		display: none;
	}

	.navbar-offset {
		padding-top: 64px;
		height: calc(100% - 64px);
	}
}
