.video-container {
	display: inline-block;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
}

.video {
	display: block;
	width: 100%;
}
